<template>
  <link rel="stylesheet" href="https://web.archive.org/web/20201126184900cs_/https://fonts.googleapis.com/css?family=Poppins:400,500,700&amp;display=swap">
  <link rel="stylesheet" href="https://web.archive.org/web/20201126184900cs_/https://yobithemes.com/demo/html/freda/src/app/css/uikit-custom.min.css">
  <link rel="stylesheet" href="https://web.archive.org/web/20201126184900cs_/https://yobithemes.com/demo/html/freda/src/app/css/app.css">
  <header class="yb-main-header" id="main-header">

    <!-- home section -->
    <div class="yb-full-height " id="home">



      <section class="yb-profile-desc">
        <div class="yb-section-point-wrapper" data-uk-parallax="opacity: 1, 0;" style="opacity: 1;">
          <div class="uk-container">
            <div class="yb-section-point">
              <sup>01</sup>
              <span>welcome</span>
            </div>
          </div>
        </div>
        <div class="yb-graphics">
          <div class="yb-graphic-1" data-uk-parallax="blur: 0,30;" style="filter: blur(0px);"></div>
          <div class="yb-graphic-2" data-uk-parallax="blur: 0,30;" style="filter: blur(0px);"></div>
        </div>
        <div class="uk-container uk-text-center yb-profile-desc-wrap uk-position-relative">
          <img src="/web/20201126184900im_/https://yobithemes.com/demo/html/freda/src/img/x1.png" alt="" width="60">
          <h1 class="uk-text-bold yb-gradient-text"><span>Koit</span> <span>Vilbas</span></h1>
          <div class="uk-text-bold yb-myjob">
            Im a <span id="typed" class="typed">software developer</span><span class="typed-cursor">|</span>
          </div>
        </div>
      </section>

    </div><!-- end home section -->

    <!-- main menu -->
    <nav class="yb-main-menu" id="main-menu">
      <div class="uk-container uk-navbar" data-uk-navbar="">
        <div class="uk-navbar-left">
          <a class="yb-logo uk-navbar-item uk-logo" href="dark-index.html"><span>Freda</span></a>
        </div>
        <div class="uk-navbar-right uk-hidden@m">
          <a class="uk-navbar-toggle" href="#" id="btn-menu-toggle">
            <span data-uk-navbar-toggle-icon="" class="yb-icon-menu uk-icon uk-navbar-toggle-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="navbar-toggle-icon"><rect y="9" width="20" height="2"></rect><rect y="3" width="20" height="2"></rect><rect y="15" width="20" height="2"></rect></svg></span>
            <span data-uk-icon="close" class="yb-icon-close uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="close"><path fill="none" stroke="#000" stroke-width="1.06" d="M16,16 L4,4"></path><path fill="none" stroke="#000" stroke-width="1.06" d="M16,4 L4,16"></path></svg></span>
          </a>
        </div>
        <div class="yb-menu-toggle-wrapper" id="menucollapse">
          <div class="uk-navbar-center">
            <ul class="uk-navbar-nav" data-uk-scrollspy-nav="closest: li; scroll: false; offset: 79 ">
              <li class="uk-active">
                <a class="yb-inner-link" href="#home"> Home </a>
              </li>
              <li class="">
                <a class="yb-inner-link" href="#about"> About</a>
              </li>
              <li class="">
                <a class="yb-inner-link" href="#resume"> Resume </a>
              </li>
              <li>
                <a class="yb-inner-link" href="#portfolio"> Portfolio </a>
              </li>
              <li>
                <a class="yb-inner-link" href="#blog"> Blog </a>
              </li>
              <li>
                <a class="yb-inner-link" href="#contact"> Contact </a>
              </li>
            </ul>
          </div>
          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav yb-social-icons">
              <li>
                <a href="#">
                  <span class="uk-icon" data-uk-icon="icon: facebook"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="facebook"><path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path></svg></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="uk-icon" data-uk-icon="icon: instagram"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="instagram"><path d="M13.55,1H6.46C3.45,1,1,3.44,1,6.44v7.12c0,3,2.45,5.44,5.46,5.44h7.08c3.02,0,5.46-2.44,5.46-5.44V6.44 C19.01,3.44,16.56,1,13.55,1z M17.5,14c0,1.93-1.57,3.5-3.5,3.5H6c-1.93,0-3.5-1.57-3.5-3.5V6c0-1.93,1.57-3.5,3.5-3.5h8 c1.93,0,3.5,1.57,3.5,3.5V14z"></path><circle cx="14.87" cy="5.26" r="1.09"></circle><path d="M10.03,5.45c-2.55,0-4.63,2.06-4.63,4.6c0,2.55,2.07,4.61,4.63,4.61c2.56,0,4.63-2.061,4.63-4.61 C14.65,7.51,12.58,5.45,10.03,5.45L10.03,5.45L10.03,5.45z M10.08,13c-1.66,0-3-1.34-3-2.99c0-1.65,1.34-2.99,3-2.99s3,1.34,3,2.99 C13.08,11.66,11.74,13,10.08,13L10.08,13L10.08,13z"></path></svg></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="uk-icon" data-uk-icon="icon: twitter"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="twitter"><path d="M19,4.74 C18.339,5.029 17.626,5.229 16.881,5.32 C17.644,4.86 18.227,4.139 18.503,3.28 C17.79,3.7 17.001,4.009 16.159,4.17 C15.485,3.45 14.526,3 13.464,3 C11.423,3 9.771,4.66 9.771,6.7 C9.771,6.99 9.804,7.269 9.868,7.539 C6.795,7.38 4.076,5.919 2.254,3.679 C1.936,4.219 1.754,4.86 1.754,5.539 C1.754,6.82 2.405,7.95 3.397,8.61 C2.79,8.589 2.22,8.429 1.723,8.149 L1.723,8.189 C1.723,9.978 2.997,11.478 4.686,11.82 C4.376,11.899 4.049,11.939 3.713,11.939 C3.475,11.939 3.245,11.919 3.018,11.88 C3.49,13.349 4.852,14.419 6.469,14.449 C5.205,15.429 3.612,16.019 1.882,16.019 C1.583,16.019 1.29,16.009 1,15.969 C2.635,17.019 4.576,17.629 6.662,17.629 C13.454,17.629 17.17,12 17.17,7.129 C17.17,6.969 17.166,6.809 17.157,6.649 C17.879,6.129 18.504,5.478 19,4.74"></path></svg></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav><!-- end main menu -->
  </header>
  <main class="yb-main-content">
    <!-- about section -->
    <section id="about" class="yb-section">
      <div class="yb-my-profile">
        <div class="uk-container">
          <div class="uk-grid-collapse uk-child-width-expand@m uk-grid" data-uk-grid="">
            <div class="uk-first-column">
              <div class="yb-my-profile-img uk-background-cover uk-background-norepeat uk-background-top-center" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/profile.jpg" data-uk-img="" data-uk-lightbox="">
                <a href="https://web.archive.org/web/20201126184900/https://www.youtube.com/watch?v=uQBL7pSAXR8" class="yb-my-intro">
                  <span data-uk-icon="icon: play; ratio: 2" class="uk-icon"><svg width="40" height="40" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="play"><polygon fill="none" stroke="#000" points="6.5,5 14.5,10 6.5,15"></polygon></svg></span>
                  <span class="yb-my-intro-text">Introduction</span>
                </a>
              </div>
            </div>
            <div>
              <div class="yb-my-profile-desc">
                <div class="yb-section-point-wrapper">
                  <div class="yb-section-point right">
                    <sup>02</sup>
                    <span>About</span>
                  </div>
                </div>
                <div class="yb-my-profile-desc-top">
                  <h2 class="yb-section-title">Hello There!</h2>
                  <p>
                    I discovered my passion for design when I was a sophomore in
                    High School; It was then when it became clear what I wanted to
                    do for a living. I pursued my career at the amazing Valencia
                    College where I started my major in Print Design. A year later
                    I took a begginer’s Web class, where I discovered the love for
                    code. After that, I switched my major to Interactive Design
                    and started the never-ending journey of becoming a web
                    developer along with sharpening my eye for design.
                  </p>
                </div>
                <div class="yb-my-profile-desc-bottom yb-bg-soft">
                  <ul class="yb-my-profile-desc-info uk-list">
                    <li>
                      <span>Birthdate</span> : 09/11/1990
                    </li>
                    <li>
                      <span>Skype</span> : my.username
                    </li>
                    <li>
                      <span>Phone</span> : + 123-456-789-426
                    </li>
                    <li>
                      <span>Email</span> : get.someone@gmail.com
                    </li>
                    <li>
                      <span>Website</span> : www.creativedomain.com
                    </li>
                    <li>
                      <span>Addresses</span> : Collins Street West Victoria 8007 Australia
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="yb-border-top yb-border-bottom ">
        <div class="uk-container">
          <div data-uk-grid="" class="yb-box-services uk-child-width-1-4@l uk-child-width-1-2 uk-text-center uk-grid-divider uk-grid">
            <div class="uk-first-column">
              <div class="yb-box-service">
                <span class="uk-icon" data-uk-icon="icon: laptop; ratio: 1.5"><svg width="30" height="30" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="laptop"><rect y="16" width="20" height="1"></rect><rect fill="none" stroke="#000" x="2.5" y="4.5" width="15" height="10"></rect></svg></span>
                <h5>Web Developer</h5>
              </div>
            </div>
            <div>
              <div class="yb-box-service">
                <span class="uk-icon" data-uk-icon="icon: paint-bucket; ratio: 1.5"><svg width="30" height="30" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="paint-bucket"><path d="M10.21,1 L0,11.21 L8.1,19.31 L18.31,9.1 L10.21,1 L10.21,1 Z M16.89,9.1 L15,11 L1.7,11 L10.21,2.42 L16.89,9.1 Z"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M6.42,2.33 L11.7,7.61"></path><path d="M18.49,12 C18.49,12 20,14.06 20,15.36 C20,16.28 19.24,17 18.49,17 L18.49,17 C17.74,17 17,16.28 17,15.36 C17,14.06 18.49,12 18.49,12 L18.49,12 Z"></path></svg></span>
                <h5>Web Designer</h5>
              </div>
            </div>
            <div class="uk-grid-margin uk-first-column">
              <div class="yb-box-service">
                <span class="uk-icon" data-uk-icon="icon: world; ratio: 1.5"><svg width="30" height="30" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="world"><path fill="none" stroke="#000" d="M1,10.5 L19,10.5"></path><path fill="none" stroke="#000" d="M2.35,15.5 L17.65,15.5"></path><path fill="none" stroke="#000" d="M2.35,5.5 L17.523,5.5"></path><path fill="none" stroke="#000" d="M10,19.46 L9.98,19.46 C7.31,17.33 5.61,14.141 5.61,10.58 C5.61,7.02 7.33,3.83 10,1.7 C10.01,1.7 9.99,1.7 10,1.7 L10,1.7 C12.67,3.83 14.4,7.02 14.4,10.58 C14.4,14.141 12.67,17.33 10,19.46 L10,19.46 L10,19.46 L10,19.46 Z"></path><circle fill="none" stroke="#000" cx="10" cy="10.5" r="9"></circle></svg></span>
                <h5>Web Marketer</h5>
              </div>
            </div>
            <div class="uk-grid-margin">
              <div class="yb-box-service">
                <span class="uk-icon" data-uk-icon="icon: settings; ratio: 1.5"><svg width="30" height="30" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="settings"><ellipse fill="none" stroke="#000" cx="6.11" cy="3.55" rx="2.11" ry="2.15"></ellipse><ellipse fill="none" stroke="#000" cx="6.11" cy="15.55" rx="2.11" ry="2.15"></ellipse><circle fill="none" stroke="#000" cx="13.15" cy="9.55" r="2.15"></circle><rect x="1" y="3" width="3" height="1"></rect><rect x="10" y="3" width="8" height="1"></rect><rect x="1" y="9" width="8" height="1"></rect><rect x="15" y="9" width="3" height="1"></rect><rect x="1" y="15" width="3" height="1"></rect><rect x="10" y="15" width="8" height="1"></rect></svg></span>
                <h5>Web Tester</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- end about section -->
    <!-- resume section -->
    <section class="yb-section " id="resume">
      <div class="yb-section-point-wrapper">
        <div class="uk-container">
          <div class="yb-section-point">
            <sup>03</sup>
            <span>Resume</span>
          </div>
        </div>
      </div>
      <div class="uk-container">
        <div data-uk-grid="" class="uk-grid-divider uk-grid">
          <div class="uk-width-1-4@s uk-visible@s uk-margin-large-bottom yb-section-padding-top uk-first-column" id="resume-nav-wrapper">
            <div data-uk-sticky="bottom: #resume-nav-wrapper; offset: 100; media: 650" class="uk-sticky" style="">
              <ul class="yb-nav-left uk-tab-left uk-tab" data-uk-scrollspy-nav="closest: li; scroll: false; offset: 120">
                <li class="uk-active">
                  <a href="#education" class="yb-inner-link" data-offset="120">
                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                    Education
                  </a>
                </li>
                <li>
                  <a href="#experience" class="yb-inner-link" data-offset="120">
                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                    Experience
                  </a>
                </li>
                <li>
                  <a href="#skills" class="yb-inner-link" data-offset="120">
                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                    Skills
                  </a>
                </li>
                <li>
                  <a href="#awwwards" class="yb-inner-link" data-offset="120">
                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                    Awards
                  </a>
                </li>
              </ul>
            </div><div class="uk-sticky-placeholder" style="height: 125px; margin: 0px;" hidden=""></div>
          </div>
          <div class="uk-width-expand@s yb-section-padding-top">
            <div>
              <h2 class="yb-section-title uk-heading-line" id="education">
                <span>Education</span>
              </h2>
              <div class="yb-resume-list">
                <ul class="uk-list uk-list-large uk-list-divider">
                  <li>
                    <div class="yb-resume-item">
                      <div class="yb-resume-item-date">
                        <div class="yb-resume-item-date-icon"> <span data-uk-icon="calendar" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="calendar"><path d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z"></path><rect width="1" height="3" x="6" y="2"></rect><rect width="1" height="3" x="13" y="2"></rect></svg></span></div>
                        <div class="yb-resume-item-date-text"><span>2011 - 2013</span></div>
                      </div>
                      <h4 class="yb-section-title">Bachelor of Science in Computer Science</h4>
                      <strong>International University</strong>
                      <div class="uk-text-small">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiei dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="yb-resume-item">
                      <div class="yb-resume-item-date">
                        <div class="yb-resume-item-date-icon"> <span data-uk-icon="calendar" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="calendar"><path d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z"></path><rect width="1" height="3" x="6" y="2"></rect><rect width="1" height="3" x="13" y="2"></rect></svg></span></div>
                        <div class="yb-resume-item-date-text"><span>2008 - 2011</span></div>
                      </div>
                      <h4 class="yb-section-title">Computer Processing Systems/Computer Software</h4>
                      <strong>Longford Tech</strong>
                      <div class="uk-text-small">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiei dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h2 class="yb-section-title  uk-heading-line " id="experience">
                <span>Experience</span>
              </h2>
              <div class="yb-resume-list">
                <ul class="uk-list uk-list-large uk-list-divider">
                  <li>
                    <div class="yb-resume-item">
                      <div class="yb-resume-item-date">
                        <div class="yb-resume-item-date-icon"> <span data-uk-icon="calendar" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="calendar"><path d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z"></path><rect width="1" height="3" x="6" y="2"></rect><rect width="1" height="3" x="13" y="2"></rect></svg></span></div>
                        <div class="yb-resume-item-date-text"><span>2018 - Present</span></div>
                      </div>
                      <h4 class="yb-section-title">Software Developer</h4>
                      <strong>River Tech</strong>
                      <div class="uk-text-small">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiei dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="yb-resume-item">
                      <div class="yb-resume-item-date">
                        <div class="yb-resume-item-date-icon"> <span data-uk-icon="calendar" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="calendar"><path d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z"></path><rect width="1" height="3" x="6" y="2"></rect><rect width="1" height="3" x="13" y="2"></rect></svg></span></div>
                        <div class="yb-resume-item-date-text"><span>2016 - 2018</span></div>
                      </div>
                      <h4 class="yb-section-title">Web Designer</h4>
                      <strong>Retail Ocean</strong>
                      <div class="uk-text-small">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiei dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="yb-resume-item">
                      <div class="yb-resume-item-date">
                        <div class="yb-resume-item-date-icon"> <span data-uk-icon="calendar" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="calendar"><path d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z"></path><rect width="1" height="3" x="6" y="2"></rect><rect width="1" height="3" x="13" y="2"></rect></svg></span></div>
                        <div class="yb-resume-item-date-text"><span>2014 - 2016</span></div>
                      </div>
                      <h4 class="yb-section-title">Web Marketing</h4>
                      <strong>Proper Team</strong>
                      <div class="uk-text-small">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiei dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="yb-resume-item">
                      <div class="yb-resume-item-date">
                        <div class="yb-resume-item-date-icon"> <span data-uk-icon="calendar" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="calendar"><path d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z"></path><rect width="1" height="3" x="6" y="2"></rect><rect width="1" height="3" x="13" y="2"></rect></svg></span></div>
                        <div class="yb-resume-item-date-text"><span>2013 - 2014</span></div>
                      </div>
                      <h4 class="yb-section-title">Web Tester</h4>
                      <strong>Ota Group</strong>
                      <div class="uk-text-small">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiei dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h2 class="yb-section-title  uk-heading-line " id="skills">
                <span>Skills</span>
              </h2>
              <div class="yb-margin-top-1 yb-margin-bottom-2">
                <div class="uk-grid-collapse uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-text-center uk-grid" data-uk-grid="">
                  <div class="yb-bg-soft uk-first-column">
                    <div class="yb-box-circle">
                      <div class="circle-progress " data-value="0.9"><canvas width="100" height="100"></canvas>
                        <strong>90<i>%</i></strong>
                      </div>
                      <h5 class="yb-box-circle-title">CSS</h5>
                    </div>
                  </div>
                  <div>
                    <div class="yb-box-circle">
                      <div class="circle-progress " data-value="0.9"><canvas width="100" height="100"></canvas>
                        <strong>90<i>%</i></strong>
                      </div>
                      <h5 class="yb-box-circle-title">HTML</h5>
                    </div>
                  </div>
                  <div class="yb-bg-soft">
                    <div class="yb-box-circle">
                      <div class="circle-progress " data-value="1"><canvas width="100" height="100"></canvas>
                        <strong>100<i>%</i></strong>
                      </div>
                      <h5 class="yb-box-circle-title">PHP</h5>
                    </div>
                  </div>
                  <div>
                    <div class="yb-box-circle">
                      <div class="circle-progress " data-value="0.8"><canvas width="100" height="100"></canvas>
                        <strong>80<i>%</i></strong>
                      </div>
                      <h5 class="yb-box-circle-title">Flutter</h5>
                    </div>
                  </div>
                  <div class="uk-grid-margin uk-first-column">
                    <div class="yb-box-circle">
                      <div class="circle-progress " data-value="0.6"><canvas width="100" height="100"></canvas>
                        <strong>60<i>%</i></strong>
                      </div>
                      <h5 class="yb-box-circle-title">ROR</h5>
                    </div>
                  </div>
                  <div class="yb-bg-soft uk-grid-margin">
                    <div class="yb-box-circle">
                      <div class="circle-progress " data-value="0.7"><canvas width="100" height="100"></canvas>
                        <strong>70<i>%</i></strong>
                      </div>
                      <h5 class="yb-box-circle-title">Elixir</h5>
                    </div>
                  </div>
                  <div class="uk-grid-margin">
                    <div class="yb-box-circle">
                      <div class="circle-progress " data-value="0.8"><canvas width="100" height="100"></canvas>
                        <strong>80<i>%</i></strong>
                      </div>
                      <h5 class="yb-box-circle-title">Git</h5>
                    </div>
                  </div>
                  <div class="yb-bg-soft uk-grid-margin">
                    <div class="yb-box-circle">
                      <div class="circle-progress " data-value="0.9"><canvas width="100" height="100"></canvas>
                        <strong>90<i>%</i></strong>
                      </div>
                      <h5 class="yb-box-circle-title">Mysql</h5>
                    </div>
                  </div>
                </div>
                <div data-uk-grid="" class="uk-child-width-1-2 uk-grid">
                  <div class="uk-first-column">
                    <h4 class="yb-section-title">Soft Skills</h4>
                    <ul class="uk-list uk-list-large  uk-list-divider">
                      <li>
                        <div class="yb-resume-skill-item">
                          <h5>
                            Problem Solving <small class="uk-float-right uk-text-bold uk-visible@s">100%</small>
                          </h5>
                          <progress class="uk-progress" value="100" max="100"></progress>
                        </div>
                      </li>
                      <li>
                        <div class="yb-resume-skill-item">
                          <h5>
                            Creativity <small class="uk-float-right uk-text-bold uk-visible@s">90%</small>
                          </h5>
                          <progress class="uk-progress" value="90" max="100"></progress>
                        </div>
                      </li>
                      <li>
                        <div class="yb-resume-skill-item">
                          <h5>
                            Time Management <small class="uk-float-right uk-text-bold uk-visible@s">80%</small>
                          </h5>
                          <progress class="uk-progress" value="80" max="100"></progress>
                        </div>
                      </li>
                      <li>
                        <div class="yb-resume-skill-item">
                          <h5>
                            Team Work <small class="uk-float-right uk-text-bold uk-visible@s">90%</small>
                          </h5>
                          <progress class="uk-progress" value="90" max="100"></progress>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4 class="yb-section-title">Language </h4>
                    <ul class="uk-list uk-list-large uk-list-divider">
                      <li>
                        <div class="yb-resume-skill-item">
                          <h5>
                            English <small class="uk-float-right uk-text-bold uk-visible@s">100%</small>
                          </h5>
                          <progress class="uk-progress" value="100" max="100"></progress>
                        </div>
                      </li>
                      <li>
                        <div class="yb-resume-skill-item">
                          <h5>
                            French <small class="uk-float-right uk-text-bold uk-visible@s">70%</small>
                          </h5>
                          <progress class="uk-progress" value="70" max="100"></progress>
                        </div>
                      </li>
                      <li>
                        <div class="yb-resume-skill-item">
                          <h5>
                            Chinese <small class="uk-float-right uk-text-bold uk-visible@s">80%</small>
                          </h5>
                          <progress class="uk-progress" value="80" max="100"></progress>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 class="yb-section-title  uk-heading-line " id="awwwards">
                <span>Awards</span>
              </h2>
              <div class="yb-resume-list">
                <ul class="uk-list uk-list-large uk-list-divider">
                  <li>
                    <div class="yb-resume-item">
                      <div class="yb-resume-item-date">
                        <div class="yb-resume-item-date-icon"> <span data-uk-icon="calendar" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="calendar"><path d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z"></path><rect width="1" height="3" x="6" y="2"></rect><rect width="1" height="3" x="13" y="2"></rect></svg></span></div>
                        <div class="yb-resume-item-date-text"><span>2018 - 2019</span></div>
                      </div>
                      <h4 class="yb-section-title">Top 10 Web Developer</h4>
                      <strong>Ota Group</strong>
                      <div class="uk-text-small">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiei dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="yb-resume-item">
                      <div class="yb-resume-item-date">
                        <div class="yb-resume-item-date-icon"> <span data-uk-icon="calendar" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="calendar"><path d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z"></path><rect width="1" height="3" x="6" y="2"></rect><rect width="1" height="3" x="13" y="2"></rect></svg></span></div>
                        <div class="yb-resume-item-date-text"><span>2015 - 2016</span></div>
                      </div>
                      <h4 class="yb-section-title">Top 5 LeaderShip Exellence Winner</h4>
                      <strong>Proper Team</strong>
                      <div class="uk-text-small">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiei dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="yb-resume-item">
                      <div class="yb-resume-item-date">
                        <div class="yb-resume-item-date-icon"> <span data-uk-icon="calendar" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="calendar"><path d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z"></path><rect width="1" height="3" x="6" y="2"></rect><rect width="1" height="3" x="13" y="2"></rect></svg></span></div>
                        <div class="yb-resume-item-date-text"><span>2012 - 2015</span></div>
                      </div>
                      <h4 class="yb-section-title">Top 4 Web Tester</h4>
                      <strong>Rival Ocean</strong>
                      <div class="uk-text-small">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiei dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="yb-section yb-section-padding uk-background-cover uk-background-fixed " data-uk-parallax="bgy: -200" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/bg/bg2.jpg" data-uk-img="" style="background-position-y: calc(0px);">
        <div class="yb-overlay-primary uk-position-cover"></div>
        <div class="uk-container uk-text-center uk-position-relative">
          <div data-uk-grid="" class="uk-grid-small uk-flex-center uk-grid">
            <div class="uk-first-column">
              <a href="#" class=" yb-btn uk-button uk-button-primary uk-button-large">Download CV</a>
            </div>
            <div>
              <a href="#contact" class="yb-inner-link yb-btn uk-button uk-button-danger  uk-button-large">Hire Me</a>
            </div>
          </div>
        </div>
      </div>
    </section><!-- end resume section -->
    <!-- portfolio section -->
    <section class="yb-section  uk-overflow-hidden" id="portfolio">
      <div class="yb-section-point-wrapper">
        <div class="uk-container">
          <div class="yb-section-point">
            <sup>04</sup>
            <span>Portfolio</span>
          </div>
        </div>
      </div>
      <div class="uk-container ">
        <div data-uk-grid="" class="uk-grid-divider uk-grid">
          <div class="uk-width-1-4@s uk-visible@s uk-margin-large-bottom yb-section-padding-top uk-first-column" id="portfolio-nav-wrapper">
            <div data-uk-sticky="bottom: #portfolio-nav-wrapper; offset: 100; media: 650" class="uk-sticky">
              <ul class="yb-nav-left uk-tab-left uk-tab" data-uk-scrollspy-nav="closest: li; scroll: false; offset: 120">
                <li class="uk-active">
                  <a href="#myworks" class="yb-inner-link" data-offset="120">
                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                    My Works
                  </a>
                </li>
                <li>
                  <a href="#pricing" class="yb-inner-link" data-offset="120">
                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="#testi" class="yb-inner-link" data-offset="120">
                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                    Testimonial
                  </a>
                </li>
                <li>
                  <a href="#client" class="yb-inner-link" data-offset="120">
                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                    Clients
                  </a>
                </li>
              </ul>
            </div><div class="uk-sticky-placeholder" style="height: 125px; margin: 0px;" hidden=""></div>
          </div>

          <div class="uk-width-expand@s yb-section-padding-top">
            <div>
              <h2 class="yb-section-title uk-heading-line " id="myworks"><span>My Works</span></h2>
              <div data-uk-filter="target: .js-filter" class="yb-margin-top-1 yb-margin-bottom-1">
                <ul class="yb-nav-filter uk-subnav uk-subnav-pill ">
                  <li><a data-uk-filter-control="" class="uk-active">All</a></li>
                  <li><a data-uk-filter-control=".data-web">Web</a></li>
                  <li><a data-uk-filter-control=".data-brand">Brand</a></li>
                  <li><a data-uk-filter-control=".data-design">Design</a></li>
                </ul>
                <ul class="js-filter uk-child-width-1-2 uk-grid-small uk-grid" data-uk-grid="" data-uk-lightbox="animation: fade;  toggle: .yb-lightbox">
                  <li class="data-web uk-first-column">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/01.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/01.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Web">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-01.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-01.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Web</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="data-brand data-web">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/02.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/02.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Brand, Web">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-02.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-02.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Brand, Web</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="data-web uk-grid-margin uk-first-column">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/03.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/03.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Web">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-03.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-03.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Web</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="data-web data-design uk-grid-margin">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/04.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/04.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Web, Design">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-04.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-04.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Web, Design</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="data-design uk-grid-margin uk-first-column">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/05.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/05.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Design">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-05.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-05.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Design</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="data-design uk-grid-margin">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/06.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/06.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Design">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-06.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-06.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Design</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="data-brand uk-grid-margin uk-first-column">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/07.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/07.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Brand">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-07.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-07.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Brand</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="data-design uk-grid-margin">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/08.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/08.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Design">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-08.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-08.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Design</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="data-brand uk-grid-margin uk-first-column">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/09.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/09.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Brand">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-09.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-09.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Brand</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="data-web uk-grid-margin">
                    <div class="yb-work-item uk-transition-toggle">
                      <div class="yb-work-item-img-wrap">
                        <div class="yb-work-item-img">
                          <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;383&quot; height=&quot;574&quot;></svg>" data-src="src/img/work/10.jpg" class="uk-transition-scale-up uk-transition-opaque" alt="" data-uk-img="" width="383" height="574">
                        </div>
                        <div class="yb-work-item-overlay uk-transition-fade uk-position-cover uk-position-small uk-overlay  uk-flex uk-flex-center uk-flex-middle">
                          <div>
                            <a href="src/img/work/10.jpg" class="yb-lightbox uk-transition-slide-left" data-caption="Caption Title Web">
                              <span data-uk-icon="icon:plus; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus"><rect x="9" y="1" width="1" height="17"></rect><rect x="1" y="9" width="17" height="1"></rect></svg></span>
                            </a>
                            <a href="project/project-details-10.html" class="uk-transition-slide-right show-portfolio">
                              <span data-uk-icon="icon:link; ratio:1.2" class="uk-icon"><svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"><path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path><path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <a href="project/project-details-10.html" class="show-portfolio">
                        <div class="yb-work-item-title">
                          <h5>Project Title</h5>
                          <span>Web</span>
                        </div>
                      </a>
                    </div>
                  </li>
                </ul>
                <div id="show-portofolio-details" class="uk-modal-full uk-modal" data-uk-modal="">
                  <div class="uk-modal-dialog ">
                    <button class="uk-modal-close-full uk-close-large uk-icon uk-close" type="button" data-uk-close=""><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="close-large"><line fill="none" stroke="#000" stroke-width="1.4" x1="1" y1="1" x2="19" y2="19"></line><line fill="none" stroke="#000" stroke-width="1.4" x1="19" y1="1" x2="1" y2="19"></line></svg></button>
                    <div id="portofolio-details" data-uk-height-viewport="" class="uk-animation-toggle uk-overflow-hidden">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="pricing">
              <h2 class="yb-section-title uk-heading-line "><span>Pricing</span></h2>
              <div class="yb-margin-top-1 yb-margin-bottom-2">
                <div data-uk-grid="" class="uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-1 uk-grid-medium uk-grid">
                  <div class="uk-first-column">
                    <div class="yb-pricing-item">
                      <div class="uk-card">
                        <div class="uk-card-header">
                          <h4>Front-End Developer</h4>
                          <div class="yb-pricing-item-price">
                            <strong>$ 40</strong>
                            <span>/h</span>
                          </div>
                        </div>
                        <div class="uk-card-body">
                          <small class="uk-text-muted">Details:</small>
                          <ul class="uk-list  uk-list-divider uk-text-small">
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              HTML5
                            </li>
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              CSS3
                            </li>
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              Angular jS
                            </li>
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              Vue jS
                            </li>
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              React jS
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="yb-pricing-item">
                      <div class="uk-card">
                        <div class="uk-card-header">
                          <h4>Back-End Developer</h4>
                          <div class="yb-pricing-item-price">
                            <strong>$ 60</strong>
                            <span>/h</span>
                          </div>
                        </div>
                        <div class="uk-card-body">
                          <small class="uk-text-muted">Details:</small>
                          <ul class="uk-list  uk-list-divider uk-text-small">
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              Ruby on Rails
                            </li>
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              Laravel
                            </li>
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              Go
                            </li>
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              Node Js
                            </li>
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              Phoenix Elixir
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="yb-pricing-item">
                      <div class="uk-card">
                        <div class="uk-card-header">
                          <h4>Full-Stact Developer</h4>
                          <div class="yb-pricing-item-price">
                            <strong>$ 80</strong>
                            <span>/h</span>
                          </div>
                        </div>
                        <div class="uk-card-body">
                          <small class="uk-text-muted">Details:</small>
                          <ul class="uk-list  uk-list-divider uk-text-small">
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              <strong>Included</strong> Front-End Developer
                            </li>
                            <li>
                              <span class="uk-float-right uk-text-muted uk-icon" data-uk-icon="check"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                              <strong>Included</strong> Back-End Developer
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 class="yb-section-title uk-heading-line " id="testi"><span>Testimonial</span></h2>
              <div data-uk-slider="" class="uk-slider">
                <div class="yb-margin-top-1 yb-margin-bottom-2">
                  <div data-uk-grid="" class="uk-grid-small uk-grid">
                    <div class="uk-width-expand uk-first-column">
                      <div class="uk-slider-container">
                        <ul class="yb-slider-items uk-slider-items uk-child-width-1-1" style="transform: translate3d(0px, 0px, 0px);">
                          <li tabindex="-1" class="uk-active">
                            <div class="yb-testi-item">
                              <div class="yb-testi-item-desc">
                                <h4>Nice Design</h4>
                                For the first time I see such high-quality work, everything is just fine. The design is incredible,
                                a
                                lot of features and ready-made solutions.
                              </div>
                              <div class="yb-testi-item-img">
                                <div data-uk-grid="" class="uk-flex-middle uk-grid-small uk-grid">
                                  <div class="uk-first-column">
                                    <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;300&quot; height=&quot;300&quot;></svg>" data-src="src/img/people/01.jpg" alt="" data-uk-img="" width="300" height="300">
                                  </div>
                                  <div class="uk-text-bold uk-text-small">
                                    <span>Ben</span>
                                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                                    <span>Envato User</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li tabindex="-1" class="">
                            <div class="yb-testi-item">
                              <div class="yb-testi-item-desc">
                                <h4>Top Expectation</h4>
                                For the first time I see such high-quality work, everything is just fine. The design is incredible,
                                a
                                lot of features and ready-made solutions.
                              </div>
                              <div class="yb-testi-item-img">
                                <div data-uk-grid="" class="uk-flex-middle uk-grid-small uk-grid">
                                  <div class="uk-first-column">
                                    <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;300&quot; height=&quot;300&quot;></svg>" data-src="src/img/people/02.jpg" alt="" data-uk-img="" width="300" height="300">
                                  </div>
                                  <div class="uk-text-bold uk-text-small">
                                    <span>Ben</span>
                                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                                    <span>Envato User</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li tabindex="-1">
                            <div class="yb-testi-item">
                              <div class="yb-testi-item-desc">
                                <h4>Wonderfull Work</h4>
                                For the first time I see such high-quality work, everything is just fine. The design is incredible,
                                a
                                lot of features and ready-made solutions.
                              </div>
                              <div class="yb-testi-item-img">
                                <div data-uk-grid="" class="uk-flex-middle uk-grid-small uk-grid">
                                  <div class="uk-first-column">
                                    <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;300&quot; height=&quot;300&quot;></svg>" data-src="src/img/people/03.jpg" alt="" data-uk-img="" width="300" height="300">
                                  </div>
                                  <div class="uk-text-bold uk-text-small">
                                    <span>Ben</span>
                                    <span data-uk-icon="minus" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="minus"><rect height="1" width="18" y="9" x="1"></rect></svg></span>
                                    <span>Envato User</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="uk-width-auto">
                      <div class="yb-testi-nav">
                        <a href="#" data-uk-slider-item="next">
                          <span data-uk-icon="arrow-right" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="arrow-right"><polyline fill="none" stroke="#000" points="10 5 15 9.5 10 14"></polyline><line fill="none" stroke="#000" x1="4" y1="9.5" x2="15" y2="9.5"></line></svg></span>
                        </a>
                        <a href="#" data-uk-slider-item="previous">
                          <span data-uk-icon="arrow-left" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="arrow-left"><polyline fill="none" stroke="#000" points="10 14 5 9.5 10 5"></polyline><line fill="none" stroke="#000" x1="16" y1="9.5" x2="5" y2="9.52"></line></svg></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 class="yb-section-title uk-heading-line " id="client"><span>Clients</span></h2>
              <div class="yb-margin-top-1 yb-margin-bottom-2">
                <div data-uk-grid="" class="uk-child-width-1-6@m uk-child-width-1-3 uk-grid">
                  <div class="uk-first-column">
                    <div class="yb-client-item">
                      <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;300&quot; height=&quot;300&quot;></svg>" data-src="src/img/client/01.png" alt="" class="uk-border-rounded" data-uk-img="" width="300" height="300">
                    </div>
                  </div>
                  <div>
                    <div class="yb-client-item">
                      <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;300&quot; height=&quot;300&quot;></svg>" data-src="src/img/client/02.png" alt="" class="uk-border-rounded" data-uk-img="" width="300" height="300">
                    </div>
                  </div>
                  <div>
                    <div class="yb-client-item">
                      <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;300&quot; height=&quot;300&quot;></svg>" data-src="src/img/client/03.png" alt="" class="uk-border-rounded" data-uk-img="" width="300" height="300">
                    </div>
                  </div>
                  <div>
                    <div class="yb-client-item">
                      <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;300&quot; height=&quot;300&quot;></svg>" data-src="src/img/client/04.png" alt="" class="uk-border-rounded" data-uk-img="" width="300" height="300">
                    </div>
                  </div>
                  <div>
                    <div class="yb-client-item">
                      <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;300&quot; height=&quot;300&quot;></svg>" data-src="src/img/client/05.png" alt="" class="uk-border-rounded" data-uk-img="" width="300" height="300">
                    </div>
                  </div>
                  <div>
                    <div class="yb-client-item">
                      <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;300&quot; height=&quot;300&quot;></svg>" data-src="src/img/client/06.png" alt="" class="uk-border-rounded" data-uk-img="" width="300" height="300">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="yb-border-top yb-border-bottom">
        <div class="uk-container">
          <div data-uk-grid="" class="yb-box-services uk-child-width-1-4@l uk-child-width-1-2 uk-text-center uk-grid-divider uk-grid">
            <div class="uk-first-column">
              <div class="yb-box-fact">
                <div class="yb-box-fact-count uk-h3">4441</div>
                <span class="yb-box-fact-desc ">Jobs Done</span>
              </div>
            </div>
            <div>
              <div class="yb-box-fact">
                <div class="yb-box-fact-count uk-h3">100%</div>
                <span class="yb-box-fact-desc">Recommended</span>
              </div>
            </div>
            <div class="uk-grid-margin uk-first-column">
              <div class="yb-box-fact">
                <div class="yb-box-fact-count uk-h3">673</div>
                <span class="yb-box-fact-desc">Repeat Clients</span>
              </div>
            </div>
            <div class="uk-grid-margin">
              <div class="yb-box-fact">
                <div class="yb-box-fact-count uk-h3">99%</div>
                <span class="yb-box-fact-desc">Happy Clients</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- end portfolio section -->
    <!-- blog section -->
    <section class="yb-section yb-section-padding" id="blog">
      <div class="yb-section-point-wrapper">
        <div class="uk-container">
          <div class="yb-section-point">
            <sup>05</sup>
            <span>Blog</span>
          </div>
        </div>
      </div>
      <div class="uk-container">
        <h2 class="yb-section-title uk-heading-line"><span>Latest News</span></h2>

        <div data-uk-grid="" class="uk-grid-medium yb-margin-top-1 yb-margin-bottom-2 uk-grid">
          <div class="uk-width-2-3@m uk-first-column">
            <a href="dark-blog-single.html">
              <div class="yb-blog-item uk-background-cover" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/blog/01.jpg" data-uk-img="">
                <div class="uk-overlay uk-position-cover"> </div>
                <div class="uk-overlay uk-position-top">
                  <span class="uk-badge">Life Story</span>
                </div>
                <div class="uk-overlay uk-position-bottom">
                  <h5 class="yb-blog-item-title uk-text-truncate">This Week’s Top Stories About Holidays</h5>
                  <div class="yb-blog-item-meta">
                    <div>
                      <span>May 7, 2019</span>
                    </div>
                    <div>
                      <span data-uk-icon="comment" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="comment"><path d="M6,18.71 L6,14 L1,14 L1,1 L19,1 L19,14 L10.71,14 L6,18.71 L6,18.71 Z M2,13 L7,13 L7,16.29 L10.29,13 L18,13 L18,2 L2,2 L2,13 L2,13 Z"></path></svg></span> 120
                    </div>
                    <div>
                      <span data-uk-icon="heart" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="heart"><path fill="none" stroke="#000" stroke-width="1.03" d="M10,4 C10,4 8.1,2 5.74,2 C3.38,2 1,3.55 1,6.73 C1,8.84 2.67,10.44 2.67,10.44 L10,18 L17.33,10.44 C17.33,10.44 19,8.84 19,6.73 C19,3.55 16.62,2 14.26,2 C11.9,2 10,4 10,4 L10,4 Z"></path></svg></span> 89
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="uk-width-1-3@m uk-width-1-2@s">
            <a href="dark-blog-single.html">
              <div class="yb-blog-item uk-background-cover" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/blog/02.jpg" data-uk-img="">
                <div class="uk-overlay uk-position-cover"> </div>
                <div class="uk-overlay uk-position-top">
                  <span class="uk-badge">Life Story</span>
                </div>
                <div class="uk-overlay uk-position-bottom">
                  <h5 class="yb-blog-item-title uk-text-truncate">This Week’s Top Stories About Holidays</h5>
                  <div class="yb-blog-item-meta">
                    <div>
                      <span>May 7, 2019</span>
                    </div>
                    <div>
                      <span data-uk-icon="comment" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="comment"><path d="M6,18.71 L6,14 L1,14 L1,1 L19,1 L19,14 L10.71,14 L6,18.71 L6,18.71 Z M2,13 L7,13 L7,16.29 L10.29,13 L18,13 L18,2 L2,2 L2,13 L2,13 Z"></path></svg></span> 120
                    </div>
                    <div>
                      <span data-uk-icon="heart" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="heart"><path fill="none" stroke="#000" stroke-width="1.03" d="M10,4 C10,4 8.1,2 5.74,2 C3.38,2 1,3.55 1,6.73 C1,8.84 2.67,10.44 2.67,10.44 L10,18 L17.33,10.44 C17.33,10.44 19,8.84 19,6.73 C19,3.55 16.62,2 14.26,2 C11.9,2 10,4 10,4 L10,4 Z"></path></svg></span> 89
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="uk-width-1-3@m uk-width-1-2@s uk-grid-margin uk-first-column">
            <a href="dark-blog-single.html">
              <div class="yb-blog-item uk-background-cover" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/blog/03.jpg" data-uk-img="">
                <div class="uk-overlay uk-position-cover"> </div>
                <div class="uk-overlay uk-position-top">
                  <span class="uk-badge">Life Story</span>
                </div>
                <div class="uk-overlay uk-position-bottom">
                  <h5 class="yb-blog-item-title uk-text-truncate">This Week’s Top Stories About Holidays</h5>
                  <div class="yb-blog-item-meta">
                    <div>
                      <span>May 7, 2019</span>
                    </div>
                    <div>
                      <span data-uk-icon="comment" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="comment"><path d="M6,18.71 L6,14 L1,14 L1,1 L19,1 L19,14 L10.71,14 L6,18.71 L6,18.71 Z M2,13 L7,13 L7,16.29 L10.29,13 L18,13 L18,2 L2,2 L2,13 L2,13 Z"></path></svg></span> 120
                    </div>
                    <div>
                      <span data-uk-icon="heart" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="heart"><path fill="none" stroke="#000" stroke-width="1.03" d="M10,4 C10,4 8.1,2 5.74,2 C3.38,2 1,3.55 1,6.73 C1,8.84 2.67,10.44 2.67,10.44 L10,18 L17.33,10.44 C17.33,10.44 19,8.84 19,6.73 C19,3.55 16.62,2 14.26,2 C11.9,2 10,4 10,4 L10,4 Z"></path></svg></span> 89
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="uk-width-1-3@m uk-width-1-2@s uk-grid-margin">
            <a href="dark-blog-single.html">
              <div class="yb-blog-item uk-background-cover" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/blog/04.jpg" data-uk-img="">
                <div class="uk-overlay uk-position-cover"> </div>
                <div class="uk-overlay uk-position-top">
                  <span class="uk-badge">Life Story</span>
                </div>
                <div class="uk-overlay uk-position-bottom">
                  <h5 class="yb-blog-item-title uk-text-truncate">This Week’s Top Stories About Holidays</h5>
                  <div class="yb-blog-item-meta">
                    <div>
                      <span>May 7, 2019</span>
                    </div>
                    <div>
                      <span data-uk-icon="comment" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="comment"><path d="M6,18.71 L6,14 L1,14 L1,1 L19,1 L19,14 L10.71,14 L6,18.71 L6,18.71 Z M2,13 L7,13 L7,16.29 L10.29,13 L18,13 L18,2 L2,2 L2,13 L2,13 Z"></path></svg></span> 120
                    </div>
                    <div>
                      <span data-uk-icon="heart" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="heart"><path fill="none" stroke="#000" stroke-width="1.03" d="M10,4 C10,4 8.1,2 5.74,2 C3.38,2 1,3.55 1,6.73 C1,8.84 2.67,10.44 2.67,10.44 L10,18 L17.33,10.44 C17.33,10.44 19,8.84 19,6.73 C19,3.55 16.62,2 14.26,2 C11.9,2 10,4 10,4 L10,4 Z"></path></svg></span> 89
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="uk-width-1-3@m uk-width-1-2@s uk-grid-margin">
            <a href="dark-blog-single.html">
              <div class="yb-blog-item uk-background-cover" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/blog/05.jpg" data-uk-img="">
                <div class="uk-overlay uk-position-cover"> </div>
                <div class="uk-overlay uk-position-top">
                  <span class="uk-badge">Life Story</span>
                </div>
                <div class="uk-overlay uk-position-bottom">
                  <h5 class="yb-blog-item-title uk-text-truncate">This Week’s Top Stories About Holidays</h5>
                  <div class="yb-blog-item-meta">
                    <div>
                      <span>May 7, 2019</span>
                    </div>
                    <div>
                      <span data-uk-icon="comment" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="comment"><path d="M6,18.71 L6,14 L1,14 L1,1 L19,1 L19,14 L10.71,14 L6,18.71 L6,18.71 Z M2,13 L7,13 L7,16.29 L10.29,13 L18,13 L18,2 L2,2 L2,13 L2,13 Z"></path></svg></span> 120
                    </div>
                    <div>
                      <span data-uk-icon="heart" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="heart"><path fill="none" stroke="#000" stroke-width="1.03" d="M10,4 C10,4 8.1,2 5.74,2 C3.38,2 1,3.55 1,6.73 C1,8.84 2.67,10.44 2.67,10.44 L10,18 L17.33,10.44 C17.33,10.44 19,8.84 19,6.73 C19,3.55 16.62,2 14.26,2 C11.9,2 10,4 10,4 L10,4 Z"></path></svg></span> 89
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <h5 class="uk-heading-line uk-text-right uk-text-bold">
          <a href="dark-blog-list.html" class="uk-link-heading">See More
            <span data-uk-icon="icon:arrow-right; ratio: 2" class="uk-icon"><svg width="40" height="40" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="arrow-right"><polyline fill="none" stroke="#000" points="10 5 15 9.5 10 14"></polyline><line fill="none" stroke="#000" x1="4" y1="9.5" x2="15" y2="9.5"></line></svg></span>
          </a>
        </h5>
      </div>
    </section><!-- end blog section -->
    <!-- contact section -->
    <section class="yb-section yb-border-top" id="contact">
      <div class="yb-section-point-wrapper">
        <div class="uk-container">
          <div class="yb-section-point ">
            <sup>06</sup>
            <span>Contact</span>
          </div>
        </div>
      </div>
      <div class="uk-container">
        <div data-uk-grid="" class="uk-grid-divider uk-grid">
          <div class="yb-section-padding yb-contact-left uk-width-3-4@m uk-first-column">
            <div>
              <h2 class="yb-section-title uk-heading-line ">
                <span>Get in touch</span>
              </h2>
              <p class="uk-text-bold">Feel free to contact me</p>
              <div id="contact-form-alert"></div>
              <form id="contact-form" class="uk-form-stacked">
                <div class="uk-grid uk-grid-small uk-child-width-1-2@s">
                  <div>
                    <div class="uk-margin">
                      <label for="f_email" class="uk-form-label">Email*</label>
                      <div class="uk-form-controls uk-inline uk-width-1-1">
                        <span class="uk-form-icon uk-icon" data-uk-icon="icon: user"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="user"><circle fill="none" stroke="#000" stroke-width="1.1" cx="9.9" cy="6.4" r="4.4"></circle><path fill="none" stroke="#000" stroke-width="1.1" d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2"></path></svg></span>
                        <input class="yb-input uk-input  uk-border-rounded" type="email" name="f_email" id="f_email" placeholder=" Your email here . . .">
                      </div>
                      <small class="uk-text-danger err-msg" id="f_email_error"></small>
                    </div>
                  </div>
                  <div>
                    <div class="uk-margin">
                      <label for="f_phone" class="uk-form-label">Phone*</label>
                      <div class="uk-form-controls uk-inline uk-width-1-1">
                        <span class="uk-form-icon uk-icon" data-uk-icon="icon: phone"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="phone"><path fill="none" stroke="#000" d="M15.5,17 C15.5,17.8 14.8,18.5 14,18.5 L7,18.5 C6.2,18.5 5.5,17.8 5.5,17 L5.5,3 C5.5,2.2 6.2,1.5 7,1.5 L14,1.5 C14.8,1.5 15.5,2.2 15.5,3 L15.5,17 L15.5,17 L15.5,17 Z"></path><circle cx="10.5" cy="16.5" r=".8"></circle></svg></span>
                        <input class="yb-input uk-input uk-border-rounded" type="text" name="f_phone" id="f_phone" placeholder=" Your phone here . . .">
                      </div>
                      <small class="uk-text-danger err-msg" id="f_phone_error"></small>
                    </div>
                  </div>
                </div>
                <div class="uk-margin">
                  <label class="uk-form-label">Subject*</label>
                  <div class="uk-form-controls uk-grid-small uk-child-width-auto uk-grid">
                    <label><input class="uk-radio" type="radio" name="f_subject" value="General" checked="">
                      General</label>
                    <label><input class="uk-radio" type="radio" name="f_subject" value="Project Preview"> Project
                      Review</label>
                    <label><input class="uk-radio" type="radio" name="f_subject" value="Hire Me"> Hire
                      Me</label>
                  </div>
                </div>
                <div class="uk-margin ">
                  <label for="f_message" class="uk-form-label">Message*</label>
                  <div class="uk-form-controls">
                    <textarea class="yb-input  uk-textarea uk-border-rounded" rows="7" name="f_message" id="f_message" placeholder="Your message here . . ."></textarea>
                  </div>
                  <small class="uk-text-danger err-msg" id="f_message_error"></small>
                </div>
                <div class="uk-margin-top">
                  <button class="uk-button uk-button-primary uk-button-large  yb-btn" type="submit" id="btn-contact-form" data-textreset="Send Now">
                    <span class="uk-margin-small-right btn-text-wrap">Send Now</span>
                    <span data-uk-icon="arrow-right" class="uk-icon"> <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="arrow-right"><polyline fill="none" stroke="#000" points="10 5 15 9.5 10 14"></polyline><line fill="none" stroke="#000" x1="4" y1="9.5" x2="15" y2="9.5"></line></svg></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class=" uk-width-1-4@m yb-section-padding yb-contact-right">
            <p class="uk-margin-medium-bottom uk-visible@m">
              <img src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;500&quot; height=&quot;500&quot;></svg>" data-src="src/img/profile2.jpg" data-uk-img="" alt="" class="uk-border-circle" width="500" height="500">
            </p>
            <ul class="uk-list uk-list-divider uk-text-small yb-contact-mini">
              <li class="uk-flex uk-flex-middle">
                <span data-uk-icon="mail" class="uk-flex-none uk-text-primary uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="mail"><polyline fill="none" stroke="#000" points="1.4,6.5 10,11 18.6,6.5"></polyline><path d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z"></path></svg></span>
                <span>my.email@gmail.com</span>
              </li>
              <li class="uk-flex uk-flex-middle">
                <span data-uk-icon="phone" class="uk-flex-none uk-text-primary uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="phone"><path fill="none" stroke="#000" d="M15.5,17 C15.5,17.8 14.8,18.5 14,18.5 L7,18.5 C6.2,18.5 5.5,17.8 5.5,17 L5.5,3 C5.5,2.2 6.2,1.5 7,1.5 L14,1.5 C14.8,1.5 15.5,2.2 15.5,3 L15.5,17 L15.5,17 L15.5,17 Z"></path><circle cx="10.5" cy="16.5" r=".8"></circle></svg></span>
                <span> +62-123-123-213</span>
              </li>
              <li class="uk-flex uk-flex-middle">
                <span data-uk-icon="location" class="uk-flex-none uk-text-primary uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="location"><path fill="none" stroke="#000" stroke-width="1.01" d="M10,0.5 C6.41,0.5 3.5,3.39 3.5,6.98 C3.5,11.83 10,19 10,19 C10,19 16.5,11.83 16.5,6.98 C16.5,3.39 13.59,0.5 10,0.5 L10,0.5 Z"></path><circle fill="none" stroke="#000" cx="10" cy="6.8" r="2.3"></circle></svg></span>
                <span>Lamda Street West Cibinong 8027 Bogor</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- end contact section -->

    <footer class="yb-main-footer-wrapper">
      <div class="waveHorizontals">
        <div id="waveHorizontal1" class="waveHorizontal" data-uk-img="" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/bg/wave1.svg"></div>
        <div id="waveHorizontal2" class="waveHorizontal" data-uk-img="" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/bg/wave2.svg"></div>
        <div id="waveHorizontal3" class="waveHorizontal" data-uk-img="" data-src="/web/20201126184900oe_/https://yobithemes.com/demo/html/freda/src/img/bg/wave3.svg"></div>
      </div>
      <a href="#body-app" class="uk-visible@s yb-totop yb-inner-link uk-icon uk-totop" data-uk-totop=""><svg width="18" height="10" viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg" data-svg="totop"><polyline fill="none" stroke="#000" stroke-width="1.2" points="1 9 9 1 17 9 "></polyline></svg></a>
      <div class="yb-main-footer">
        <div class="uk-container">
          <div data-uk-grid="" class="uk-child-width-1-2@s uk-grid">
            <div class="uk-margin-remove uk-text-left@s uk-first-column">
              © 2019 Freda, by YobiThemes.
            </div>
            <div class="uk-margin-remove uk-text-right@s">
              <div class="yb-footer-social">
                <a href="#">Facebook</a>
                <a href="#">Instagram</a>
                <a href="#">Twitter</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </main>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
